import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Socket } from 'ngx-socket-io';

import { SocketEvents } from './socket-events';
import { SocketSession } from './models/socket-session';

@Injectable({ providedIn: 'root' })
export class SocketService {

  constructor(private socket: Socket) {}

  /**
   * @description Listen events from socket
   * @template T class to object result
   * @param {string} event Name of event to subscribe
   * @return {Observable<T>} Subscription
   */
  public listen<T>(event: string): Observable<T> {
    return this.socket.fromEvent(event);
  }

  /**
   * @description Configurate user with socket instance to work
   * @param {SocketSession} session User session to add to room
   * @return {void}
   */
  public initConfiguration(session: SocketSession): void {
    session.socketId = this.socket.ioSocket.id;
    this.socket.emit(SocketEvents.CONFIG_ROOM, session);
  }

  /**
   * @description Verify if exists socket instance and send configuration
   * @param {SocketSession} session Session to send configuration
   * @return {void }
   */
  public checkConfiguration(session: SocketSession): void {
    if (this.socket.ioSocket.id) {
      session.socketId = this.socket.ioSocket.id;
      this.socket.emit(SocketEvents.CONFIG_ROOM, session);
    }
  }
}
