import { Directive, ElementRef, AfterViewInit, Input, OnChanges } from '@angular/core';

import { CONSTANTS } from '../../constants';

@Directive({
  selector: '[autoFocus], [appAutoFocus]'
})
export class AutoFocusDirective implements AfterViewInit, OnChanges {
  @Input() autoFocus: boolean;
  constructor(private el: ElementRef) { }

  /**
   * @description After view Init component.
   * @return {void}
   */
  public ngAfterViewInit(): void {
    this.el.nativeElement.focus();
  }

  /**
   * @description Detect changes in attribute component
   * @return {void}
   */
  public ngOnChanges(): void {
    if (this.autoFocus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, CONSTANTS.THIRTY);
    }
  }
}
