import { WepConfig } from '../env-config/wep-config.interface';

export const ENVIRONMENT: WepConfig = {
  PRODUCTION: true,
  API: 'https://136-review-develop-3zknud.netlogistik.com',
  SOCKET_URI: 'http://127.0.0.1:3006',
  MSGTIME: '2000',
  DISPLAYTIME: '100',
  ALLOWEDROUTES: 'allowed_routes',
  OFFLINE_ERROR: 'OFFLINE_ERROR',
  CLIENTID: '5f67292d76431262648c5b5533db2ade',
  CLIENTSECRET: '5ebe2294ecd0e0f08eab7690d2a6ee69',
  RFCLIENTID: 'Y9aLkqQyTHo26t4ja9DZiuqoxvvM3v78',
  RFCLIENTSECRET: '7agv9ICHTlHPqNNRmecXWAf1AWFsonAz',
  RF: 'ws://192.168.10.183:3006',
  APIPLANNING: 'https://410-review-develop-3zknud.netlogistik.com/planning/api',
  API_INTEGRATOR: 'https://410-review-develop-3zknud.netlogistik.com/integrator/',
  googleMapsKey: 'AIzaSyB4YnyugZLz3nwGwJnojTj6q_B7BSej-tQ',
  APIPRINTER: 'http://127.0.0.1:3050',
  APPINSIGHTS_INSTRUMENTATIONKEY: 'f920f6c6-8722-499c-8fbc-c76e21fb8061',
  PASSWORD: '$2a$10$HTixOGf7OLjX32w7QnLlpOKxAQJ3SGACqyzGCV290efe75LRPT07u',
  TRACKING_PRODUCT_CODE_GUIDES_TIMEOUT: 10000,
  INVENTORY_LOAD_MAX_FILE_SIZE: 26214400
};
