import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CommonModule, DatePipe } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgModule } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { GridWrapperComponent } from './grid-wrapper.component';
@NgModule({
  imports: [ButtonModule, CommonModule, DropdownModule, FormsModule, InputSwitchModule, ListboxModule, OverlayPanelModule, TableModule,
    ToolbarModule, TooltipModule, DialogModule, KeyFilterModule, AutoCompleteModule, PanelMenuModule, MatFormFieldModule, MatInputModule,
    MatIconModule, NgxMaskModule.forRoot()],
  declarations: [GridWrapperComponent],
  exports: [GridWrapperComponent],
  providers: [DatePipe]
})
export class GridWrapperModule { }
