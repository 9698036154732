import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { AuthService } from '../shared/authentication/services/auth.service';
import { ENVIRONMENT } from '../../environments/environment';
import { Session } from './session';

const MODULE = 'session/';

@Injectable()
export class SessionService {
  constructor(private http: HttpClient,
    private authService: AuthService) { }

  /**
  * @description This method returns the current user's Session object
  * @return {Session} current user's Session
  * TODO: Replace this method with an actual backend call
  */
  public getUserSession(): Session {
    let userSession = new Session();
    let userToken = this.authService.getTokenInfo();

    userSession.userId = userToken.userId;
    userSession.warehouseId = userToken.warehouseId;
    userSession.accountId = userToken.accountId;
    userSession.equipmentId = this.authService.retrieveEquipmentId();

    return userSession;
  }

  /**
   * @description This method returns the whole sessions from backend
   * @return {Observable<string>}
   */
  public getSessions(): Observable<string> {
    return this.http.get<string>(ENVIRONMENT.API + MODULE)
      .pipe(catchError((error: any) => throwError(error.json().error || 'Server error')));
  }

  /**
   * @description This method verifies the authenticated session of the backend validating its expiration
   * @return {Observable<string>}
   */
  public caducateSession(session: Session): Observable<string> {
    return this.http.delete<string>(ENVIRONMENT.API + MODULE + session.id)
      .pipe(catchError((error: any) => throwError(error.json().error || 'Server error')));
  }

  /**
   * @description This method verifies the authenticated backend session by verifying the muticuenta sessions that are successful
   * @return {Observable<string>}
   */
  public caducateAllSession(): Observable<string> {
    return this.http.delete<string>(ENVIRONMENT.API + MODULE)
      .pipe(catchError((error: any) => throwError(error.json().error || 'Server error')));
  }
}
